<template>
  <statement-table />
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

import StatementTable from "../../components/statement/StatementTable";
import { FETCHING_STATEMENTS } from "../../store/modules/statement/statement.actions.type";
import { GET_STATEMENTS } from "../../store/modules/statement/statement.getters.type";

export default {
  name: "Statement",
  components: {
    StatementTable,
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      if (store.getters[GET_STATEMENTS].length === 0) {
        await store.dispatch(FETCHING_STATEMENTS);
      }
    });
  },
};
</script>

<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #start>
          <h5 class="p-m-0">
            {{ $t("statement.table_title") }}
          </h5>
        </template>

        <template #end>
          <!-- Element for testing -->
          <input
            v-model="statementSelected"
            type="hidden"
            data-testid="statementsSelector"
          />
          <Dropdown
            v-model="statementSelected"
            :options="statements"
            option-label="date"
            option-value="date"
            :placeholder="translation.placeholder"
          />
          <Button
            label=""
            icon="pi pi-refresh"
            class="p-button-secondary p-ml-3 p-mr-2 p-mb*2"
            data-testid="table-refresh"
            @click="refreshTable"
          />
        </template>
      </Toolbar>
      <DataTable
        :value="operations"
        :paginator="true"
        :resizable-columns="true"
        column-resize-mode="expand"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column :header="translation.date" :sortable="true">
          <template #body="slotProps">
            {{ dateToString(slotProps.data.occurredOn) }}
          </template>
        </Column>
        <Column
          field="description"
          :header="translation.processName"
          :sortable="true"
        />
        <Column :header="translation.timeInc" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.time.variationInMinutes() }}
          </template>
        </Column>
        <Column :header="translation.time" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.time.availableInMinutes() }}
          </template>
        </Column>
        <Column :header="translation.storageVar" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.storage.humanReadableVariation() }}
          </template>
        </Column>
        <Column :header="translation.storage" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.storage.humanReadableAvailable() }}
          </template>
        </Column>
        <template #empty>{{ $t("statement.table_empty") }}</template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Toolbar from "primevue/toolbar";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { dateTransformer } from "../../service/utils";
import { FETCHING_STATEMENTS } from "../../store/modules/statement/statement.actions.type";
import { GET_STATEMENTS } from "../../store/modules/statement/statement.getters.type";

export default {
  name: "StatementTable",
  components: {
    Button,
    Column,
    DataTable,
    Dropdown,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const statements = computed(() => store.getters[GET_STATEMENTS]);
    const statementSelected = ref();
    const operations = computed(() => {
      const filtered = statements.value.filter(
        (statement) => statement.date === statementSelected.value
      );
      return filtered.length === 0 ? [] : filtered[0].operations;
    });
    const translation = {
      date: t("statement.table_column_date"),
      processName: t("statement.table_column_process_name"),
      timeInc: t("statement.table_column_time_inc"),
      time: t("statement.table_column_time_total"),
      storageVar: t("statement.table_column_storage_variation"),
      storage: t("statement.table_column_storage_total"),
      placeholder: t("statement.dropdown_placeholder"),
    };

    const refreshTable = async () => await store.dispatch(FETCHING_STATEMENTS);

    const dateToString = (date) => dateTransformer.toString(date);

    return {
      refreshTable,
      statementSelected,
      statements,
      operations,
      translation,
      dateToString,
    };
  },
};
</script>

<style scoped>
.p-dropdown {
  width: 12rem;
}
</style>
